/* eslint-disable camelcase */
import * as Yup from 'yup';
import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Snackbar,
  Alert,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { postData, setStorageItem } from '../../../utils/generalFunctions';
import { VALIDATE_OTP_SIGN_IN, SEND_OTP } from '../../../utils/urls';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'left',
    severity: 'error'
  });

  const [message, setMessage] = useState('')

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    username: Yup.string()
      .min(9, 'Too Short!')
      .max(12, 'Too Long!').required('Telephone number is required'),
    password: Yup.string()
      .min(4, 'Too Short!').required('Password/PIN is required'),
    OTP: Yup.string().when('validate', {
      is: true,
      then: Yup.string().required('OTP is required')
    }).notRequired(),
    validate: Yup.bool().required('This field is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      username: '',
      remember: true,
      OTP: '',
      validate: false,
      country_code: '', 
      scope: 'authentication'
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      if (!values.validate) {
        postData(SEND_OTP, { mobile: values.username, scope: values.scope, country_code: values.country_code, password: values.password })
          .then(data => {
            if (data.status) {
              formik.setFieldValue('validate', true)
              formik.setSubmitting(false)
              setMessage(data.message)
              setState({ ...state, open: true, validationSMS: data.message, severity: 'success' });
            } else {
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'error' });
              formik.setSubmitting(false)
            }
          }).catch(error => {
            setMessage(`Server not reachable,please try again later. ...${error.message}`)
            setState({ ...state, open: true, severity: 'error' });
            formik.setSubmitting(false)
          });
      } else {
        postData(VALIDATE_OTP_SIGN_IN, values)
          .then(data => {
            if (data.status) {
              const { user, profile, refresh_token, access_token } = data.data
              const { user_id } = user
              setStorageItem('auth_token', access_token)
              setStorageItem('refresh_token', refresh_token)
              setStorageItem('display_name', profile.account_.name)
              setStorageItem('acount_', JSON.stringify(profile))
              setStorageItem('user_id', user_id)
              setStorageItem('savings_id', user.default_savings_id)
              setStorageItem('user', JSON.stringify(user))
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'success' });
              navigate('/dashboard/app', { replace: true });
            } else {
              setMessage(data.message)
              setState({ ...state, open: true, severity: 'error' });
              formik.setSubmitting(false)
            }
          }).catch((error) => {
            setMessage("Server not reachable,please try again later.")
            setState({ ...state, open: true, severity: 'error' });
            console.log(error.message);
            formik.setSubmitting(false)
          });
      }


    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const { vertical, horizontal, severity, open } = state;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
  };

  const handleUsernameChange = (e) => {
    formik.setFieldValue('username', e.target.value) // .replaceAll(" ", '').substr(-9)
  }

  const dataStack = (
    <Stack spacing={3}>
      <TextField
        fullWidth
        autoComplete="username"
        type="tel"
        label="Telephone number"
        onChange={handleUsernameChange}
        value={values.username}
        error={Boolean(touched.username && errors.username)}
        helperText={touched.username && errors.username}
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="end">
        //       <Typography>+254</Typography>
        //     </InputAdornment>
        //   )
        // }}
      />

      <TextField
        fullWidth
        autoComplete="current-password"
        type={showPassword ? 'text' : 'password'}
        label="Password"
        {...getFieldProps('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          )
        }}
        error={Boolean(touched.password && errors.password)}
        helperText={touched.password && errors.password}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
          label="Remember me"
        />

        <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </Stack>
  )

  const validationsStack = (
    <Stack spacing={3}>
      <TextField
        fullWidth
        autoComplete="username"
        type="tel"
        disabled
        value={state.validationSMS}
      />
      <TextField
        fullWidth
        autoComplete="OTP"
        type="text"
        label="One Time Password"
        {...getFieldProps('OTP')}
        error={Boolean(touched.OTP && errors.OTP)}
        helperText={touched.OTP && errors.OTP}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        color='secondary'
        variant="contained"
        loading={isSubmitting}
      >
        Validate Phone Number
      </LoadingButton>
    </Stack>
  )

  return (
    <>
      <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {values.validate ? validationsStack : dataStack}
        </Form>
      </FormikProvider>
    </>
  );
}
